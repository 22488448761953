<template>
    <div class="course-list-component">
        <div class="course-list">
            <div class="course-item" v-for="(item, index) in list" :key="index" @click="toDetail(item)" v-show="index < maxNum">
                <img :src="staticPath + item.cnLogo" alt="">
                <h2>{{ item.cnTitle }}</h2>
                <p>{{ item.cnContentSummary }}</p>
                <p class="date" v-if="item.date">{{ item.date | formatDate}}</p>
                <p class="date" v-if="item.updateTime">{{ item.updateTime | formatDate }}</p>
                <i class="icon-more"></i>
            </div>
        </div>
    </div>
</template>
<script>
    import {staticPath} from '@/lib/common'
    export default {
        components: {
        },
        props: {
            list: {
                type: Array,
                default: () => {
                    return []
                }
            },
            maxNum: {
                type: Number,
                default: 12
            }
        },
        data() {
            return {
                staticPath
            };
        },
        methods: {
            toDetail(item) {
                if (item.redirectUrl.indexOf('http') !== -1) {
                    window.open(item.redirectUrl);
                } else {
                    const pid = encodeURIComponent(btoa(item.id));
                    const navigationId = encodeURIComponent(btoa(item.navigationId));
                    this.$router.push({
                        name: 'ActivityDetail',
                        params: {
                            id: pid
                        },
                        query: {
                            n: navigationId
                        }
                    })
                }
            },
        }
    };
</script>
<style lang="less" scoped>
    .course-list-component {
        .course-list {
            display: flex;
            flex-wrap: wrap;
            .course-item {
                width: 22%;
                background: #fff;
                margin-bottom: 4vw;
                border-radius: 8px 8px 8px 0;
                position: relative;
                overflow: hidden;
                box-sizing: border-box;
                margin-right: 4%;
                padding-bottom: 2vw;
                cursor: pointer;
                box-shadow: 0 5px 15px 2px rgba(190, 238, 252, 0.4);
                &:nth-child(4n) {
                    margin-right: 0;
                }
                h2 {
                    margin: 10px 0;
                    font-size: 0.9375vw;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    word-break: break-all;
                    padding: 0 1em;
                    color: #606060;
                }
                p {
                    height: 3em;
                    padding: 0 1em;
                    line-height: 150%;
                    color: #aaa;
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                .date {
                    color: #aaa;
                    margin-top: 10px;
                }
                /*> img {*/
                /*    height: 200px;*/
                /*}*/
                .icon-more {
                    display: inline-block;
                    width: 1.458vw;
                    height: 0.9375vw;
                    background: url('../../../assets/images/education/icon_more.png') no-repeat center center;
                    background-size: 100% 100%;
                    position: absolute;
                    left: 1em;
                    bottom: 1.5em;
                }
                &:hover {
                    .price {
                        transform: translateX(0);
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        .course-list-component{
            .course-list{
                display:block;
                .course-item{
                    display:inline-block;
                    vertical-align:top;
                    width: 48%;
                    margin-right: 4%;
                    padding-bottom: 10%;
                    img{
                        .fontFn(110,height);
                    }
                    h2{
                        .fontFn(16);
                        padding: 0 2.6%;
                    }
                    p{
                        .fontFn(12);
                        padding: 0 2.6%;
                    }
                    .icon-more{
                        .fontFn(20,width);
                        .fontFn(16,height);
                        bottom: 6%;
                    }
                    &:nth-child(2n){
                        margin-right:0;
                    }
                }
            }
        }  
    }
</style>