<template>
    <div id="online-list">
        <img :src="require(`@/assets/images/education/${page !== 'join' ? 'banner_'+page+'.jpg' : 'banner_'+page+'.png'}`)" :class="[page !== 'join' ? 'banner' : '']" alt="">
        <div class="course" v-if="page === types[0]">
            <div class="topic">
                <img :src="require('@/assets/images/education/course.png')" alt="">
            </div>
            <div class="course-search">
                <input type="text" v-model="searchText" placeholder="请输入课程关键字搜索">
                <i class="icon-search" @click="search"></i>
            </div>
            <course-list-component :list="courses" :maxNum="9" :showPrice="true"></course-list-component>
        </div>

        <div class="student-works" v-if="page === types[1]">
            <div class="topic">
                <img :src="require('@/assets/images/education/topic_works.png')" alt="">
            </div>
            <div class="course-search">
                <input type="text" v-model="searchWorksText" placeholder="请输入作品名称搜索">
                <i class="icon-search" @click="search"></i>
            </div>
            <div class="list">
                <div class="item" v-for="(item, index) in studentsWorks" :key="index">
                    <div class="pic">
                        <div class="inside-mask" v-if="item.videoUrl">
                            <span class="icon-play" @click="handlePlayVideo(item)"></span>
                        </div>
                        <img :src="eduStaticPath + 'pic/' + item.savePath + '.png'" alt="" @click="toEduPlatform(item)">
                    </div>
                    <div class="work-info">
                        <h2 @click="toEduPlatform(item)">{{ item.name }}</h2>
                        <div class="views">
                            <i class="icon-views"></i>
                            <!--                        {{ item.readCounts }}-->
                        </div>
                        <div class="student">
                            <img :src="eduStaticPath + item.headurl" alt="">
                            <span>{{ item.username }}</span>
                        </div>
                    </div>
                    <!--<img :src="eduStaticPath + 'pic/' + item.savePath + '.png'" alt="" @click="toEduPlatform(item)">
                    <h2 @click="toEduPlatform(item)">{{ item.name }}</h2>
                    <div class="views">
                        <i class="icon-views"></i>
                    </div>
                    <div class="student">
                        <img :src="eduStaticPath + item.headurl" alt="">
                        <span>{{ item.username }}</span>
                    </div>-->
                </div>
            </div>
        </div>
        <div class="activities" v-if="page === types[2]">
            <div class="topic">
                <img :src="require('@/assets/images/education/topic_activity.png')" alt="">
            </div>
            <div class="list">
                <activities-component :list="activities"></activities-component>
            </div>
        </div>
        <div class="textbook" v-if="page === types[3]">
            <div class="topic">
                <img :src="require('@/assets/images/education/topic_textbook.png')" alt="">
            </div>
            <div class="items" v-show="showSeries">
                <ul>
                    <li :class="[activedBtnIdx === 0 ? 'actived' : '']" @click="productBtnClicked(0)">
                        {{ buttons.jycp && buttons.jycp.cnName ? buttons.jycp.cnName : null }}
                    </li>
                    <li :class="[activedBtnIdx === 1 ? 'actived' : '']" @click="productBtnClicked(1)">
                        {{ buttons.rjkc && buttons.rjkc.cnName ? buttons.rjkc.cnName : null }}
                    </li>
                </ul>
            </div>
            
            <div class="product-series" v-if="showSeries">
                <ul>
                    <li v-for="(item, idx) in seriesList" :key="idx">
                        <div @click="chooseSeries(idx)">
                            <img :src="staticPath + item.cnLogo" alt />
                            <h4>{{ item.cnName }}</h4>
                        </div>
                    </li>
                </ul>
            </div>
            
            <div class="list" v-else>
                <div class="lf">
                    <div class="filter-bar">
                        <h2 @click = "showSelect('popSelect1')">{{ $t('lang.filter') }}</h2>
                        <ul v-if="leftbar.length > 0">
                            <li v-for="(item, index) in leftbar" :key="index" :class="{'selected': filterIdx == index}" @click="chooseProduct(index)">
                                {{ item.cnName }}
                            </li>
                        </ul>
                        <h2 @click = "showSelect('popSelect2')">{{ $t('lang.particles') }}</h2>
                        <ul>
                            <li v-for="(item, index) in filterParticles" :key="index" :class="{'selected': filterParticleId === item.id}" @click="chooseParticle(index)">
                                {{ item.cnName }}
                            </li>
                        </ul>

                        <div v-show = "popSelect1 || popSelect2" class = "popBg" @click = "hideSelect"></div>
                        <ul v-show = "popSelect1" class = "h5_1" v-if="leftbar.length > 0">
                            <li v-for="(item, index) in leftbar" :key="index" :class="{'selected': filterIdx == index}" @click="chooseProduct(index)">
                                {{ item.cnName }}
                            </li>
                        </ul>
                        <ul v-show = "popSelect2" class = "h5_2">
                            <li v-for="(item, index) in filterParticles" :key="index" :class="{'selected': filterParticleId === item.id}" @click="chooseParticle(index)">
                                {{ item.cnName }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="series" v-if="productList.length > 0">
                    <div :class="['item', filterId || filterParticleId ? 'sub-item' : '']" v-for="(item, index) in productList" :key="index" @click="productClicked(index)">
                        <img :src="staticPath + item.cnLogo" alt="">
                        <div class="text" :style="{cursor: filterId ? 'pointer' : 'auto'}">
                            <p :style="{width: filterIdx === 0 ? '100%' : '0'}" v-if="filterIdx === 0 && filterParticleIdx === ''">{{ item.cnName }}</p>
                            <div class="lf" v-if="filterIdx !== 0 || filterParticleIdx !== ''">
                                <p>{{ item.cnName }}</p>
                                <span>数号: {{ item.model }}</span>
                            </div>
                            <button v-if="filterIdx !== 0 || filterParticleIdx !== ''" @click="checkDetail(item.id, index)">查看详情</button>
                        </div>
                    </div>
                </div>
                <div class="list-empty" v-else>
                    <div>
                        <img :src="require('../../assets/images/empty_robot.gif')" alt="">
                    </div>
                    <p>暂无内容!~</p>
                </div>
            </div>
        </div>
        <div class="qualification" v-if="page === types[4]">
            <img src="../../assets/images/education/qualification.jpg" alt="">
            <!--<div class="topic">
                <img :src="require('@/assets/images/education/topic_qualification.png')" alt="">
            </div>
            <div class="list">
                <activities-component :list="qualificationList"></activities-component>
            </div>-->
        </div>
    
        <div class="join" v-if="page === types[5]">
            <div class="topic">
                <img :src="require('@/assets/images/education/topic_join.png')" alt="">
            </div>
            <div class="list">
                <activities-component :list="joinList"></activities-component>
            </div>
        </div>
        
        <div class="pagination" v-if="page !== types[4] && page !== types[5]">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="changePagination"
                :current-page.sync="pageCurrent"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>

        <div class="dialog" :style="{'display': showDialog ? 'block': 'none'}">
            <div :class="['detail-box', 'animate__animated', dialogVisible ? 'animate__fadeInUp' : 'animate__fadeOutDown']">
                <i class="icon-close" @click="closeDialog"></i>
                <div class="img">
                    <swiper ref="mySwiper" :options="swiperOptions">
                        <swiper-slide v-for="(item, index) in detail.files" :key="index">
                            <div class="banner-box">
                                <img :src="staticPath + item.fileUrl" alt/>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
                <div class="detail-info" v-if="detail">
                    <h2>{{ detail.cnName }}</h2>
                    <ul>
                        <li v-if="detail.model">型 号: {{ detail.model }}</li>
                        <li v-if="detail.particleCounts">颗粒数: {{ detail.particleCounts }}</li>
                        <li v-if="detail.dollsCounts">公仔数量: {{ detail.dollsCounts }}</li>
                        <li v-if="detail.ageAdaptation">适用年龄: {{ detail.ageAdaptation }}</li>
                        <li class="manual" v-if="detail.hasManual">
                            <span>说明书:</span>
                            <p @click="descriptionDownload(detail.fileExternalUrl)">点击查看{{ detail.cnName }}电子拼装说明书</p>
                        </li>
                        
                        <li v-if="detail.cnMaterial">材 料: {{ detail.cnMaterial }}</li>
                        <li v-if="detail.cnPackagingType">包装形式: {{ detail.cnPackagingType }}</li>
                        <li v-if="detail.size">包装尺寸: {{ detail.size }}</li>
                    </ul>
                    <div class="description" @click="descriptionDownload(detail.downloadUrl)" v-if="detail.hasDownload">点击下载{{ detail.cnName }}</div>
<!--                    <div class="description" @click="descriptionDownload(detail.fileExternalUrl)" v-if="detail.hasManual">点击查看{{ detail.cnName }}电子拼装说明书</div>-->
                    <div class="purchase" v-if="detail.jingDongUrl || detail.jingDongUrl">
                        <p>购买方式:</p>
                        <a :href="detail.jingDongUrl" target="_blank" v-if="detail.jingDongUrl">京东商城</a>
                        <a :href="detail.taoBaoUrl" target="_blank" v-if="detail.taoBaoUrl">天猫商城</a>
                    </div>
                </div>
            </div>
        </div>
    
        <el-dialog
                :title="opusName"
                :visible.sync="videoDialogVisible"
                class="video-dialog"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :lock-scroll="false"
                append-to-body>
            <div class="video">
                <video width="100%" height="100%" controls :src="eduStaticPath + 'opus/' + videoUrl"></video>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import CourseListComponent from './component/courseListComponent.vue'
    import ActivitiesComponent from './component/activitiesComponent.vue'
    import "swiper/dist/css/swiper.css";
    import { swiper, swiperSlide } from "vue-awesome-swiper";

    import {urls, staticPath, eduStaticPath, eduCoverPath, eduPlatformUrl, eduAI7PlatformUrl} from '@/lib/common'
    export default {
        name: "Online",
        components: {
            CourseListComponent,
            ActivitiesComponent,
            swiper,
            swiperSlide,
        },
        data() {
            return {
                staticPath,
                eduStaticPath,
                eduCoverPath,
                swiperOptions: {
                    grabCursor: false,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                },
                courses: [],
                studentsWorks: [],
                activities: [],
                searchText: '',
                searchWorksText: '',
                pageCurrent: 1,
                pageSize: 8,
                total: 0,
                types: ['courses', 'works', 'activities', 'textbook', 'qualification', 'join'],
                page: 'textbook',
                navId: '',

                videoDialogVisible: false,
                opusName: '',
                videoUrl: '',

                leftbar: [],
                filterParticles: [
                    {
                        id: 'lt100',
                        cnName: '100以内',
                        enName: 'less 100',
                    }, {
                        id: 'lt200',
                        cnName: '200以内',
                        enName: 'less 200',
                    }, {
                        id: 'lt300',
                        cnName: '300以内',
                        enName: 'less 300',
                    }, {
                        id: 'gt300',
                        cnName: '300以上',
                        enName: 'more than 300',
                    }
                ],
                filterParticleId: '',
                filterParticleIdx: '',
                filterId: '',
                filterIdx: 0,
                showDialog: false,
                dialogVisible: false,
                productIdx: 0,
                productList: [],
                qualificationList: [],
                joinList: [],
                buttons: [],
                activedBtnIdx: 0,
                seriesList: [],
                showSeries: true,
                detail: '',

                popSelect1:false,
                popSelect2:false
            };
        },
        watch: {
            "$route.params.type": function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.page = newVal;
                    this.pageCurrent = 1;
                    this.total = 0;
                    this.parentId = atob(decodeURIComponent(this.$route.params.parentId));
                    if (this.page === this.types[3]) {
                        this.showSeries = true;
                        this.activedBtnIdx = 0;
                        /*if (this.$route.query && this.$route.query.n) {
                            this.navId = atob(decodeURIComponent(this.$route.query.n));
                            this.filterId = Number(this.navId);
                            this.getSidebar(this.parentId).then(() => {
                                this.getList();
                            });
                        } else {
                            this.filterId = this.parentId;
                            this.getSidebar(this.parentId)
                        }*/
                        this.getEducationProduct()
                        if (this.$route.query && this.$route.query.n) {
                            this.navId = atob(decodeURIComponent(this.$route.query.n));
                            this.filterId = Number(this.navId);
                        } else {
                            this.filterId = this.parentId;
                        }
                    } else {
                        this.getList()
                    }
                }
            },
            "$store.state.refreshTemp": function(newVal, oldVal) {
                if (newVal && newVal !== oldVal && !this.showSeries) {
                    this.showSeries = true;
                    this.total = 0;
                    this.productBtnClicked(0)
                    this.$store.commit('changeRefreshStatus', false)
                }
            }
        },
        methods: {
            hideSelect(){
                this.popSelect1 = false;
                this.popSelect2 = false;
            },
            showSelect(el){
                this[el] = true;
            },
            getList(isGetSeries, pageCurrent, pageSize) {
                const params = {}
                isGetSeries ? params.navigationParentId = this.parentId : params.navigationId = this.filterId || this.navId;
                if (this.filterParticleIdx !== '' && this.filterParticleIdx !== undefined) {
                    params.particle = this.filterParticleIdx + 1;
                    params.navigationParentId = this.navId;
                }
                params.pageCurrent = pageCurrent ? pageCurrent : this.pageCurrent;
                if (pageSize) params.pageSize = pageSize;
                let url = '';
                switch(this.page) {
                    case this.types[0]:
                        url = urls.getCourseList;
                        params.pageSize = 9;
                        if (this.searchText) params.name = this.searchText;
                        break;
                    case this.types[1]:
                        params.pageSize = 12;
                        url = urls.getStudentWorks;
                        if (this.searchWorksText) params.name = this.searchWorksText;
                        break;
                    case this.types[2]:
                        url = urls.getSubpageList;
                        params.pageSize = 12;
                        params.navigationId = this.parentId;
                        break;
                    case this.types[3]:
                        if (this.filterParticleIdx !== '' && this.filterParticleIdx !== undefined) {
                            params.navigationId = this.filterId;
                        } else {
                            params.navigationId = this.filterId || this.navId;
                        }
                        params.pageSize = 12;
                        url = urls.getProductList;
                        break;
                    case this.types[4]:
                        url = urls.getSubpageList;
                        params.pageSize = 8;
                        params.navigationId = this.parentId;
                        break;
                    case this.types[5]:
                        url = urls.getSubpageList;
                        params.pageSize = 8;
                        params.navigationId = this.parentId;
                        break;
                    default:
                        break;
                }
                this.pageSize = params.pageSize;
                this.$http.get(url, {params}).then(res => {
                    if (res.data.status === 200) {
                        const result =  res.data.data.records;
                        this.total = res.data.data.total;
                        switch(this.page) {
                            case this.types[0]:
                                this.courses = result;
                                break;
                            case this.types[1]:
                                this.studentsWorks = result;
                                break;
                            case this.types[2]:
                                this.activities = result;
                                break;
                            case this.types[3]:
                                this.productList = result;
                                break;
                            case this.types[4]:
                                this.qualificationList = result;
                                break;
                            case this.types[5]:
                                this.joinList = result;
                                break;
                            default:
                                break;
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            changePagination(page) {
                this.getList(false, page);
            },
            search() {
                this.getList();
            },
            handlePlayVideo(item) {
                this.videoDialogVisible = true;
                this.videoUrl = item.videoUrl;
                this.opusName = item.name
            },
            chooseParticle(index) {
                // this.filterIdx = '';
                // this.filterId = '';
                this.searchText = '';
                this.total = 0;
                this.pageCurrent = 1;

                this.filterParticleIdx = index;
                this.filterParticleId = this.filterParticles[index].id;
                this.getList(true);

                this.hideSelect();
            },
            chooseProduct(index, isSeries=false) {
                this.filterIdx = this.filterIdx = isSeries ? index + 1 : index;
                this.searchText = '';
                this.filterParticleId = '';
                this.filterParticleIdx = '';
                this.total = 0;
                this.pageCurrent = 1;
                if (index === 0) {
                    if (isSeries) {
                        this.filterId = this.leftbar[this.filterIdx].id;
                        this.getList(false);
                    } else {
                        this.filterId = '';
                        this.getSidebar(this.navId)
                    }
                } else {
                    this.filterId = this.leftbar[this.filterIdx].id;
                    this.getList(index === 0);
                }

                this.hideSelect();
            },
            productClicked(index) {
                if (this.filterIdx === 0) {
                    this.filterIdx = index + 1;
                    this.filterId = this.leftbar[index + 1].id;
                    this.getList();
                }
            },
            getSidebar(id) {
                return new Promise((resolve, reject) => {
                    this.$http.get(urls.getNavChild + id).then(res => {
                        if (res.data.status === 200) {
                            let all = {
                                id: this.navId,
                                cnName: '全部',
                                enName: 'ALL',
                            }
                            this.seriesList = res.data.data;
                            this.leftbar = JSON.parse(JSON.stringify(res.data.data));
                            this.leftbar.unshift(all);
                            this.productList = JSON.parse(JSON.stringify(res.data.data));
                            for (var i in this.leftbar) {
                                if (this.leftbar[i].id === this.filterId) {
                                    this.filterIdx = Number(i);
                                    break;
                                }
                            }
                        } else if (res.data.status !== 401 && res.data.status !== 106) {
                            this.$message.error(res.data.message)
                        }
                        resolve()
                    }).catch(err => {
                        reject(err)
                    })
                })
            },
            checkDetail(id, index) {
                if (index) this.productIdx = index;
                // 恢复显示首张轮播图（再次打开详情轮播会显示之前关闭的内容）
                this.$refs.mySwiper.swiper.activeIndex = 0;
                this.$http.get(urls.findProduct + id).then(res => {
                    if (res.data.status === 200) {
                        this.detail = res.data.data;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
                this.showDialog = true;
                this.dialogVisible = true;
            },
            closeDialog() {
                this.dialogVisible = false;
                setTimeout(() => {
                    this.showDialog = false;
                }, 400)
            },
            toEduPlatform(item) {
                var isChrome = this.getBrowserInfo() === 'Chrome';
                if(!isChrome){
                    this.$message({
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: '<p style="line-height: 150%;">我们十分抱歉，您当前使用的浏览器不支持邦宝教育编程平台所提供所有功能，<br/>我们建议您使用最新版的Google Chrome(谷歌)浏览器，以获得更佳的体验。</p>',
                        type: 'error',
                        duration: 5000,
                        center: true
                    })
                    return;
                }
                let url = item.device === 'AI7' ? eduAI7PlatformUrl : eduPlatformUrl
                window.open(`${url}?sp=${item.savePath}&name=${item.name}&mode=${item.mode}&device=${item.device}`);
            },
            getBrowserInfo() {
                var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
                var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
                var isIE = userAgent.indexOf("compatible") > -1
                    && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
                var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
                var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
                var isSafari = userAgent.indexOf("Safari") > -1
                    && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
                var isChrome = userAgent.indexOf("Chrome") > -1
                    && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

                if (isIE) {
                    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
                    reIE.test(userAgent);
                    var fIEVersion = parseFloat(RegExp["$1"]);
                    if (fIEVersion == 7) {
                        return "IE7";
                    } else if (fIEVersion == 8) {
                        return "IE8";
                    } else if (fIEVersion == 9) {
                        return "IE9";
                    } else if (fIEVersion == 10) {
                        return "IE10";
                    } else if (fIEVersion == 11) {
                        return "IE11";
                    } else {
                        return "0";
                    }
                    //IE版本过低
                    return "IE";
                }
                if (isOpera) {
                    return "Opera";
                }
                if (isEdge) {
                    return "Edge";
                }
                if (isFF) {
                    return "FF";
                }
                if (isSafari) {
                    return "Safari";
                }
                if (isChrome) {
                    return "Chrome";
                }
            },
            descriptionDownload(url) {
                let a = document.createElement('a');
                a.href = url;
                a.download = this.detail.cnName;
                a.setAttribute('target', '_blank');
                a.click();
            },
            getEducationProduct() {
                this.$http.get(urls.getEducationProduct + this.parentId).then(res => {
                    if (res.data.status === 200) {
                        this.buttons = res.data.data;
                        this.navId = res.data.data.jycp.id;
                        this.getSidebar(res.data.data.jycp.id)
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            productBtnClicked(idx) {
                if (idx === this.activedBtnIdx) {
                    return
                } else {
                    this.activedBtnIdx = idx;
                    switch (idx) {
                        case 0:
                            this.navId = this.buttons.jycp.id;
                            break;
                        case 1:
                            this.navId = this.buttons.rjkc.id;
                            break;
                        default:
                            break;
                    }
                    this.getSidebar(this.navId);
                }
            },
            chooseSeries(idx) {
                this.showSeries = false;
                this.chooseProduct(idx, true)
            }
        },
        created() {
            if (this.$route.params && this.$route.params.type) {
                this.page = this.$route.params.type;
            } else {
                this.page = this.types[0]
            }
            if (this.$route.params && this.$route.params.parentId) {
                this.parentId = atob(decodeURIComponent(this.$route.params.parentId))
            }
            if (this.page === this.types[3]) {
                this.getEducationProduct()
                if (this.$route.query && this.$route.query.n) {
                    this.navId = atob(decodeURIComponent(this.$route.query.n));
                    this.filterId = Number(this.navId);
                    // this.getSidebar(this.parentId).then(() => {
                    //     this.getList();
                    // });
                } else {
                    this.filterId = this.parentId;
                    // this.getSidebar(this.parentId);
                }
            } else {
                this.getList()
            }
        }
    };
</script>
<style lang="less" scoped>
    @deep: ~'>>>';
    #online-list {
        padding: 0 0 2%;
        background: #F2FCFF url("../../assets/images/education/online_list_bg.png") no-repeat center top;
        background-size: 100% 101%;
    }
    .banner {
        margin-bottom: 60px;
    }
    .course{
        margin: 0 auto 20px;
        
    }
    .course-search {
        text-align: center;
        margin: 3vw auto;
        input {
            height: 3vw;
            line-height: 3vw;
            min-width: 50%;
            background: #fff;
            border-radius: 2vw 0 0 2vw;
            padding: 0 12px;
            font-size: 24px;
            color: #2F3E59;
        }
        .icon-search {
            display: inline-block;
            width: 3vw;
            height: 3vw;
            line-height: 3vw;
            box-sizing: border-box;
            background: #fff url("../../assets/images/education/icon_search.png") no-repeat center center;
            background-size: 50%;
            position: relative;
            top: 1vw;
            border-radius: 0 2vw 2vw 0;
            cursor: pointer;
        }
    }

    .course-list-component{
        @{deep} .course-list{
            padding: 0 40px;
        }
    }
    .student-works {
        margin: 0 auto 20px;
        .list {
            display: flex;
            flex-wrap: wrap;
            padding: 0 40px;
            .item {
                width: 22%;
                margin: 0 4% 4% 0;
                box-sizing: border-box;
                padding: 6px;
                background: #fff;
                border-radius: 0 0 1vw 0;
                box-shadow: 0 5px 10px 1px rgba(190, 238, 252, 0.3);
                &:nth-child(4n) {
                    margin-right: 0;
                }
                .pic {
                    position: relative;
                    .inside-mask {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-color: rgba(0, 0, 0, .3);
                        .icon-play {
                            display: inline-block;
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            background: #fff url("../../assets/images/education/icon_play.png") no-repeat center center;
                            background-size: contain;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            margin-left: -30px;
                            margin-top: -30px;
                            box-shadow: 0 0 10px 1px rgba(255, 255, 255, .8);
                            cursor: pointer;
                            opacity: 0.8;
                            transition: all .3s linear;
                            &:hover {
                                box-shadow: 0 0 10px 1px rgba(69,114,160, .8);
                            }
                        }
                    }
                    >img {
                        width: 100%;
                        /*min-height: 150px;*/
                    }
                }
                h2 {
                    color: #2F3E59;
                    margin: 0.8vw 0;
                    white-space: nowrap;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    cursor: pointer;
                }
                >img {
                    cursor: pointer;
                }
                .views {
                    color: #CCCCCC;
                    .icon-views {
                        display: inline-block;
                        width: 1vw;
                        height: 0.723vw;
                        background: url("../../assets/images/icon_views_black.png") no-repeat center center;
                        background-size: 100%;
                        margin-right: 5px;
                    }
                }
                .student {
                    margin-top: 0.5vw;
                    padding: .5vw 0;
                    border-top: 1px solid #ddd;
                    line-height: 1.3vw;
                    color: #0A0A0A;
                    img {
                        width: 1.56vw;
                        margin-right: 0.6vw;
                    }
                }
            }
        }
    }
    .activities {
        margin: 0 auto 20px;
        max-width: 1300px;
    }
    .textbook {
        width: 1300px;
        margin: 0 auto;
        background-size: 100%;
        >.items {
            ul {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: nowrap;
                font-size: 16px;
                li {
                    height: 60px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 10px;
                    cursor: pointer;
                    color: #34254C;
                    margin-right: 15px;
                    width: 180px;
                    background-color: #fff;
                    transition: all .3s linear;
                    box-shadow: 0 10px 10px 2px rgba(204, 243, 255, 0.7);
                    &.actived {
                        position: relative;
                        background: linear-gradient(to right, #1BB2FF, #24CEFF);
                        color: #fff;
                        box-shadow: 0 10px 15px 1px rgba(27, 178, 255, 0.5);
                        &::after {
                            content: '';
                            position: absolute;
                            left: 50%;
                            bottom: -30px;
                            margin-left: -10px;
                            border: 10px solid transparent;
                            border-top-color: #34254C;
                        }
                    }
                }
            }
        }
        .product-series {
            ul {
                display: flex;
                flex-wrap: wrap;
                margin-top: 4vw;
                li {
                    width: 21%;
                    cursor: pointer;
                    margin: 0 5% 4% 0;
                    box-shadow: 0 10px 40px rgba(40, 40, 40, 0.15);
                    background: #fff;
                    line-height: 24px;
                    border-radius: 10px;
                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                    img {
                        width: 273px;
                        height: 273px;
                        border-radius: 10px 10px 0 0;
                    }
                    h4 {
                        text-align: center;
                        margin: 10px 0 20px;
                        font-size: 18px;
                        font-weight: normal;
                    }
                    .icon {
                        display: inline-block;
                        width: 1.45vw;
                        height: 1.07vw;
                        background: url("../../assets/images/icon_arrow.png") no-repeat 100%
                        100%;
                        background-size: contain;
                        cursor: pointer;
                        margin: 10px 20px 20px;
                    }
                }
            }
        }
        .list {
            display: flex;
            justify-content: space-between;
            >.lf {
                width: 160px;
                font-size: 14px;
                .filter-bar {
                    line-height: 300%;
                    color: #34254C;
                    h2 {
                        font-size: 24px;
                        color: #1BB2FF;
                        padding-bottom: 10px;
                        &::before {
                            content: '';
                            display: inline-block;
                            width: 1.1vw;
                            height: 1.1vw;
                            background: url('../../assets/images/education/icon_filter.png') no-repeat  center center;
                            background-size: 100%;
                            margin-right: 5px;
                            position: relative;
                            top: 2px;
                        }
                    }
                    .h5_1,
                    .h5_2{
                        display: none;
                    }
                    .selected {
                        position: relative;
                        text-decoration: underline;
                    }
                    .selected::before {
                        content: '';
                        display: inline-block;
                        border: 9px solid transparent;
                        border-left-color: #34254C;
                        border-left-width: 12px;
                        position: absolute;
                        top: 50%;
                        left: -20px;
                        margin-top: -8px;
                        border-radius: 5px;
                    }
                    ul{
                        padding-bottom: 20px;
                        li {
                            cursor: pointer;
                            font-size: 18px;
                            font-weight: bold;
                            margin-bottom: 10px;
                        }
                    }

                }
            }
            .series {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                margin-left: 0;
                .item {
                    width: 23%;
                    margin: 0 0 50px 2%;
                    text-align: center;
                    background: #fff;
                    border-radius: 15px;
                    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.07);;
                    img{
                        width: 100%;
                        height: 276px;
                        border-top-left-radius: 15px;
                        border-top-right-radius: 15px;
                    }
                    .text {
                        >p {
                            font-size: 16px;
                            margin-top: 20px;
                            color: #333;
                            font-weight: normal;
                        }
                    }
                    &.sub-item {
                        .text {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 1vw;
                            .lf {
                                text-align: left;
                                width: 55%;
                                p {
                                    font-size: 18px;
                                    font-weight: bold;
                                    margin-bottom: 10px;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    word-break: break-all;
                                }
                                span {
                                    color: #999;
                                    font-size: 14px;
                                }
                            }
                            button {
                                padding: 10px 20px;
                                border: 1px solid #ddd;
                                border-radius: 50px;
                                color: #1BB2FF;
                                font-size: 14px;
                                overflow: hidden;
                                position: relative;
                                transition: all .2s linear;
                                &::before,
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    width: 50%;
                                    height: 100%;
                                    font-size: 14px;
                                    padding-top: 10px;
                                    background-color: #1BB2FF;
                                    transition: all .15s linear;
                                }
                                &::before {
                                    content: '查看';
                                    left: -55%;
                                    text-align: right;
                                    border-radius: 50px 0 0 50px;
                                }
                                &::after {
                                    content: '详情';
                                    right: -55%;
                                    text-align: left;
                                    border-radius: 0 50px 50px 0;
                                }
                                &:hover {
                                    color: #fff;
                                    &::before {
                                        left: 0;
                                    }
                                    &::after {
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .list-empty {
                flex: 8;
                align-self: center;
                text-align: center;
                font-size: 18px;
                margin-bottom: 100px;
                >div {
                    width: 200px;
                    align-self: center;
                    height: 125px;
                    margin: 0 auto 20px;
                    overflow: hidden;
                }
            }
        }
    }
    .qualification {
        max-width: 1300px;
        margin: auto;
    }
    .join {
        max-width: 1300px;
        margin: -400px auto 100px;
    }
    .dialog {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,.7);
        z-index: 1003;
        .detail-box {
            width: 1180px;
            height: 640px;
            margin: 5% auto 0;
            padding: 2% 1% 2%;
            display: flex;
            border-radius: 10px 0 10px 0;
            justify-content: space-between;
            background: #fff url("../../assets/images/education/product_detail_bg.png") no-repeat center center;
            background-size: 100% 100%;
            position: relative;
            .icon-close {
                position: absolute;
                right: -11px;
                top: -12px;
                width: 24px;
                height: 24px;
                cursor: pointer;
                background: url("../../assets/images/products/icon_close.png") no-repeat center center;
                background-size: 100%;
            }
            .img {
                width: 720px;
                @{deep} .swiper-slide {
                    height: 720px;
                    .banner-box {
                        margin: 15px 0 0 80px;
                    }
                }
                @{deep} .swiper-button-prev {
                    width: 60px;
                    height: 60px;
                    top: 40%;
                    background-image: url("../../assets/images/products/icon_arrow_left.png") !important;
                    background-size: contain;
                }
                @{deep} .swiper-button-next {
                    width: 60px;
                    height: 60px;
                    top: 40%;
                    right: 50px;
                    background-image: url("../../assets/images/products/icon_arrow_right.png") !important;
                    background-size: contain;
                }
                img {
                    width: 520px;
                    height: 520px;
                    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                }
            }
            .detail-info {
                width: 275px;
                margin-top: 25px;
                h2 {
                    font-size: 28px;
                    margin-bottom: 20px;
                }
                ul {
                    li {
                        font-size: 18px;
                        line-height: 35px;
                        color:#34254C;
                    }
                    .manual {
                        margin-bottom: 15px;
                        span {
                            width: 4em;
                        }
                        p {
                            cursor: pointer;
                            color: #DC000C;
                            font-size: 15px;
                            padding-left: 22px;
                            line-height: 150%;
                            margin-bottom: 12px;
                            background: url("../../assets/images/down-load.png") no-repeat 2px 3px;
                            background-size: 16px;
                        }
                    }
                }
                .description {
                    cursor: pointer;
                    color: #DC000C;
                    font-size: 15px;
                    padding-left: 22px;
                    line-height: 150%;
                    margin-bottom: 12px;
                    background: url("../../assets/images/down-load.png") no-repeat 2px 3px;
                    background-size: 16px;
                }
                .purchase {
                    color: #34254C;
                    font-size: 18px;
                    >p {
                        margin: 15px 0 10px;
                    }
                    a {
                        display: inline-block;
                        font-size: 18px;
                        color: #E12319;
                        margin-right: 10px;
                        border-radius: 6px;
                        padding: 6px 24px 6px 32px;
                        background: url("../../assets/images/jd.png") no-repeat 6px center;
                        background-size: 24px;
                        border: 1px solid #FDE3E2;
                        position: relative;
                        &::after {
                            content: '';
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            background: url("../../assets/images/icon_play.png") no-repeat center center;
                            background-size: contain;
                            position: absolute;
                            right: 6px;
                            top: 10px;
                        }
                        &:last-child {
                            margin-right: 0;
                            background-image: url("../../assets/images/tmall.png");
                        }
                    }
                }
            }
        }
    }
    .pagination {
        text-align: center;
    }

    @{deep} .el-dialog {
        border-radius: 10px 10px 0 0;
        text-align: center;
        color: #34254C;
        font-size: 18px;
        background-color: rgba(0, 0, 0, 0.85);
        width: 60%;
        .el-dialog__body {
            padding: 20px 0 0 0;
        }
        .el-dialog__title {
            color: #fff;
        }
        .el-icon-close {
            font-size: 24px;
            color: #fff;
        }
        .video {
            video {
                width: 100%;
                max-height: 600px;
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #online-list{
            padding: 0 5.2% 10%;
        }


        .course-search{
            margin: 4% auto;
            position: relative;
            box-shadow: 0 0 10px #aaa;
            border-radius: 8px;
            overflow:hidden;
            width: 100%;
            input{
                .fontFn(44,height);
                .fontFn(44,line-height);
                .fontFn(14);
                width: 100%;
                padding-right: 16%;
            }
            .icon-search{
                position: absolute;
                top:0;
                right: 0;
                .fontFn(44,width);
                .fontFn(44,height);
            }
        }
        

        .course-list-component{
            @{deep} .course-list{
                width: 100%;
            }
        }

        .student-works{
            .list{
                display:block;
                .item{
                    display:inline-block;
                    vertical-align:top;
                    width: 48%;
                    margin-right: 4%;
                    &:nth-child(2n){
                        margin-right:0;
                    }
                    h2{
                        .fontFn(14);
                        margin:3% 0;
                        cursor: pointer;
                    }
                    >img {
                        cursor: pointer;
                    }
                    .views{
                        .icon-views{
                            .fontFn(20,width);
                            .fontFn(14,height);
                        }
                    }
                    .student{
                        margin-top:2%;
                        padding:3% 0;
                        .fontFn(12);
                        .fontFn(18,line-height);
                    }   
                }
            }
        }

        .textbook{
            width:100%;
            >.items {
                padding-bottom:6%;
                ul {
                    justify-content:space-between;
                    li{
                        margin:0;
                        width:48%;
                        height:36px;
                        line-height:36px;
                    }
                }
            }
            .list{
                flex-direction:column;
                    &>.lf{
                        width: 100%;
                        .filter-bar{
                            padding-left: 3%;
                            position: relative;
                            h2{
                                .fontFn(18);
                                .fontFn(18,line-height);
                                padding-top: 4%;
                                padding-right: 10%;
                                display: inline-block;
                                vertical-align: top;
                                &::before{
                                    .fontFn(16,width);
                                    .fontFn(16,height);
                                }
                            }
                            ul{
                                display: none;
                            }
                            .popBg{
                                position: fixed;
                                top:0;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                background:rgba(0,0,0,0.3);
                                z-index: 500;
                            }
                            .h5_1,
                            .h5_2{
                                display: block;
                                width: 60%;
                                position: absolute;
                                z-index: 1000;
                                background:#fff;
                                padding: 4%;
                                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.36);
                                border-radius: 10px;
                                li{
                                    line-height: 32px;
                                    font-weight: bolder;
                                    font-size: 14px;
                                }
                            }
                            .h5_2{
                                left:36%;
                            }
                            .selected{
                                padding-left: 14px;
                                font-size: 16px;
                                text-decoration: underline;
                                &::before{
                                    left:0;
                                    border-left-width: 10px;
                                }
                            }
                        }
                    }
                    .series{
                        flex: 1;
                        margin-left:0;
                        padding: 2% 0;
                        .item{
                            width: 48%;
                            margin-right:4%;
                            margin-bottom:6%;
                            margin-left: 0;
                            height: auto;
                            &:nth-child(even){
                                margin-right: 0;
                            }
                            img{
                                width: 100%;
                                .fontFn(142,height);
                            }
                            .text > p{
                                margin:0;
                                padding: 6% 4%;
                                .fontFn(12);
                            }
                        }
                        .item.sub-item{
                            padding-bottom: 2%;
                            .text{
                                padding:2% 4% 4%;
                                .lf{
                                    flex: auto;
                                    p{.fontFn(14);}
                                    span{.fontFn(12)}
                                }
                                button{
                                    flex:none;
                                    .fontFn(12);
                                    padding: 6px 12px;
                                }
                            }
                        }
                    }
                    .list-empty{
                        flex: 2;
                    }
            } 
            .product-series{
                ul{
                    li{
                        width:46%;
                        margin-right:6%;
                        &:nth-child(2n){
                            margin-right:0;
                        }
                        div{
                            img{
                                width:100%;
                                .fontFn(154,height);
                            }
                            h4{
                                .fontFn(14);
                            }
                        }
                    }
                }
            }
        }
    
        .dialog{
            z-index: 1500000;
            .detail-box{
                display: block;
                width: 100%;
                height: 100%;
                margin:0;
                border-radius: 0;
                padding: 0;
                background-image: none;
                .img{
                    width: 100%;
                    overflow:hidden;
                    .fontFn(375,height);
                    .swiper-slide{
                        height:auto;
                        .banner-box{
                            margin-left: 0;
                        }
                    }
                    img{
                        width: 100%;
                        height: auto;
                        .fontFn(375,max-height);
                        border-radius: 0;
                    }
                    .swiper-button-prev,
                    .swiper-button-next{
                        background:none;
                        width: 40px;
                        height: 40px;
                        top: 50%;
                        margin-top: -20px;
                        &::after{
                            content:'';
                            display: block;
                            position: absolute;
                            border-top: 2px solid #625F6F;
                            border-left: 2px solid #625F6F;
                            width: 12px;
                            height: 12px;
                            margin-left: -4px;
                            margin-top: -6px;
                            top: 50%;
                            left: 50%;
                        }
                    }
                    .swiper-button-prev{
                        left: 0;
                        &::after{
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -ms-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                        }
                    }
                    .swiper-button-next{
                        right:0;
                        &::after{
                            transform: rotate(135deg);
                            -webkit-transform: rotate(135deg);
                            -moz-transform: rotate(135deg);
                            -ms-transform: rotate(135deg);
                            -o-transform: rotate(135deg);
                        }
                    }
                }
                .detail-info{
                
                    width: 100%;
                    margin-top:0;
                    h2{
                        .fontFn(24);
                        margin-bottom: 0;
                        padding: 4% 4% 8%;
                        font-weight: normal;
                        color:#fff;
                        background:url('../../assets/images/products/product_detail_bg.png') no-repeat -8px 74%;
                        background-size: 150% auto;
                    }
                    ul{
                        padding:1% 4%;
                        font-size: 0;
                        li{
                            display: inline-block;
                            vertical-align: top;
                            width: 100%;
                            .fontFn(12);
                            .fontFn(20,line-height)
                        }
                    }
                    .purchase{
                        padding:0 4%;
                        .fontFn(12);
                        &>p{
                            margin:0;
                            padding:1% 0 2%;
                        }
                        a{
                            .fontFn(12);
                            &:after{
                                top:26%;
                            }
                        }
                    }
                }
                .icon-close{
                    z-index: 1500001;
                    right: auto;
                    left:10px;
                    top:20px;
                    background:rgba(0,0,0,0.5);
                    border-radius: 50%;
                    &::after{
                        content:'';
                        display: block;
                        position: absolute;
                        border-top:1px solid #fff;
                        border-left:1px solid #fff;
                        width: 8px;
                        height: 8px;
                        margin-left:-2px;
                        margin-top:-4px;
                        top:50%;
                        left:50%;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        -moz-transform: rotate(-45deg);
                        -ms-transform: rotate(-45deg);
                        -o-transform: rotate(-45deg);
                    }
                }
            }
        }
    }
</style>